import './FooterStyles.css';
import { links } from '../../testinfo';

export default function Footer(){
    return(
        <div className="footerContainer">
            <span>Contact Me</span>
            <div className='subContainer'>
                {links.map((link, index) => (
                    <a key={index} href={link.href}>
                        <img className="logo" src={link.image} alt="" />
                        <p>{link.text}</p>
                    </a>
                ))}
            </div>
            <div className='subContainer'></div>
        </div>
    )
}