import React, { useState } from "react";
import './MainViewStyles.css';
import { name, description, image, role, carouselContents} from "../../testinfo";
import Footer from "../footer/Footer";

function MainView() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimate, setIsAnimate] = useState(false);
  
  function leftNavigate(){    
    triggerAnimation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselContents.length);
  }
  function rightNavigate(){
    triggerAnimation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselContents.length) % carouselContents.length);
  }

  function triggerAnimation(){
    setIsAnimate(false);
    setTimeout(() => setIsAnimate(true), 0);
  }

  const currentItem = carouselContents[currentIndex];

  return (
    <div className="overview">
      <div className="title">
        <span>{name}</span>
        <p>{role}</p>
      </div>

      <div className="separator"></div>
        <div className="container">
          <img src={image} alt="" />
          <p className="text_overview">{description}</p>
        </div>

      <div className="separator"></div>
        <span className="sectionTitle">Work Experience</span>
        <div className="carousel">
          <button className="leftNavigate" onClick={leftNavigate}>
            <img src="left-chevron.png" alt=""/>
          </button>
          <div className={`carouselContent ${isAnimate ? 'animate' : ''}`}>
            <img src={currentItem.image} alt=''></img>
            <div className="carouselInfo" >
              <span>{currentItem.title}</span>
              <p className="companyName">{currentItem.company}</p>
              <p className="date">{currentItem.dateStart + ' - ' + currentItem.dateEnd}</p>
              <p className="description">{currentItem.description}</p>
              <div className="tagsContainer">
                {currentItem.tags.slice(0,2).map((tag) => (
                  <div className="tags">
                    {tag}
                  </div>
                ))}
                {currentItem.tags.length > 2 && <div className="tags">...</div>}
                </div>
              </div>
            </div>
      <button className="rightNavigate" onClick={rightNavigate}>
        <img src="right-chevron.png" alt=""/>
      </button>
      </div>
      <div className="separator"/>
      <Footer/>
    </div>
);
}

export default MainView;
