import './404.css';


export default function U404(){
    return (
        <div className="container">
            <p>This page does not exist</p>
            <a href="/">Click Here to Return</a>
        </div>
    )
}