export const name = 'Rhenjiro M Gunawan';
export const description = 'Motivated Computer Science Senior with foundational experience in software engineering. Passionate about self-learning and continuously enhancing proficiency in programming languages and software development concepts. Possesses internship-level experience in building mobile applications using React Native and TypeScript. Eager to contribute to dynamic teams and tackle challenging projects while furthering technical skills and industry knowledge.';
export const image = './DD1_3623.jpg';
export const role = 'University Student';
export const links = 
    [
        {href: 'https://www.linkedin.com/in/rhenjirog', text: 'Connect on LinkedIn', image:'./linkedinLogo.png'},
        {href: `mailto:rhenjirog@gmail.com` ,text: 'Send Me an Email', image:'./communication.png'},
    ];
    export const carouselContents = 
    [
        {
            href:'#',
            image: './logo512.png',
            title: 'Software Engineer Intern',
            company: 'Altonaut',
            description: 'Example Description of the company',
            dateStart: 'May 2024',
            dateEnd: 'Aug 2024',
            tags:
            ['Typescript', 'React Native', 'Docker', 'Compose']
        },
        {
            href:'#',
            image: './logo-usage-color.png',
            title: 'Computer Science Tutor',
            company: 'Shoreline Community College',
            description: 'Another Example',
            dateStart: 'March 2023',
            dateEnd: 'June 2023',
            tags:
            ['C++', 'Java', 'Python']
        },
        
    ]